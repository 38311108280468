import './404.scss';

import '../../js/nav-row';
import '../../js/dropdown-menu';

import '../../js/lazy-load';
import '../../js/footer-current-year';

import $ from 'jquery';

$(function () {
  const elements = document.getElementsByClassName('animation');
  for (const element of elements) {
    element.src = "/gif/404-animation.gif"
  }

  setTimeout(function () {
    const titles = document.getElementsByClassName('title');
    for (const title of titles) {
      title.className += ' active';
    }

    const subtitles = document.getElementsByClassName('subtitle');
    for (const subtitle of subtitles) {
      subtitle.className += ' active';
    }

  }, 1700);
});

$(function () {
  // Pathname is like this:
  // "/en/features"
  //
  // If we do a split on "/", it'll look like this:
  // "", "en", "features"
  //
  // We want the language section.
  //
  const langSection = window.location.pathname.split('/')[1];

  let lang;
  switch(langSection) {
    default:
    case "es":
      lang = "es";
      break;
    case "en":
      lang = "en";
      break;
    case "ca":
      lang = "ca";
      break;
  }

  $('html').attr('lang', lang);
});
